/**
 *
 * Landing
 *
 */

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { actions, reducer, sliceKey } from './slice';
import { selectActionType } from './selectors';
import { landingSaga } from './saga';

import { Introduction } from './components/Introduction';
import { CompanyShowcase } from './components/CompanyShowcase';
import { AppBanner } from './components/AppBanner';
import { TechStackBanner } from './components/TechStackBanner';
import { SolutionsSection } from './components/SolutionsSection';
import { RegisterModal } from './components/RegisterModal';
import { ChangePasswordPrompt } from './components/ChangePasswordPrompt';
import { Testimonial } from './components/Testimonial';

import { checkFeature } from '../../../utils/checkFeature';
import { getQueryParams } from '../../../utils/getQueryParams';

import './styles.scss';
import { useEffect } from 'react';

export function Landing(props) {
    useInjectReducer({ key: sliceKey, reducer: reducer });
    useInjectSaga({ key: sliceKey, saga: landingSaga });

    useEffect(() => {
        if (checkFeature('forgotPasswordMode')) {
            handleToggleLogin(false);
        }
        if (checkFeature('registerMode')) {
            handleRegister();
        }
    }, []);

    const dispatch = useDispatch();

    const actionType = useSelector(selectActionType);

    const handleLogin = data => {
        dispatch(actions.loginUser({ data }));
    };

    const handleRegister = () => {
        dispatch(
            actions.setActionType({ type: LandingModalTypes.REGISTER_MODAL }),
        );
    };

    const handleLoginErrors = errorObj => {
        dispatch(actions.setLoginErrors({ data: errorObj }));
    };

    const handleForgotPassword = email => {
        dispatch(actions.sendForgotPassword({ email }));
    };

    const handleForgotPasswordError = error => {
        dispatch(actions.setForgotPasswordError({ error }));
    };

    const handleOnCloseModal = () => {
        dispatch(actions.unsetActionType());
    };

    const handleToggleLogin = showLogin => {
        dispatch(actions.setShowLoginForm({ showLogin }));
    };

    const handleResendLink = () => {
        dispatch(actions.resendForgotPassword());
    };

    const handleFetchDomainCompanies = domain => {
        dispatch(actions.setRegistrationDomain({ domain }));
    };

    const handleOrganisationChange = organisation => {
        dispatch(actions.setOrganisation({ organisation }));
    };

    const handleLoadMoreSuggestions = () => {
        dispatch(actions.loadMoreOrganisation());
    };

    const handleOnRegister = data => {
        if (window) {
            const params = getQueryParams(window.location.search);
            if (params.ref && params.ref.length > 0) {
                data.regSource = params.ref;
            } else {
                data.regSource = 'dashboard';
            }
        }
        dispatch(actions.registerNewUser({ data }));
    };

    const handleSetRegisterError = (key, value) => {
        dispatch(actions.setRegisterError({ key, value }));
    };

    const handleCaptchaSubmit = data => {
        dispatch(actions.setCaptchaToken(data));
    };

    return (
        <div className="landing-page-wrapper">
            <div className="landing-page-container">
                <Introduction
                    toggleLoginForm={handleToggleLogin}
                    setLoginErrors={handleLoginErrors}
                    onLogin={handleLogin}
                    onRegister={handleRegister}
                    onForgotPassword={handleForgotPassword}
                    onForgotPasswordError={handleForgotPasswordError}
                    handleCaptchaSubmit={handleCaptchaSubmit}
                />
                <CompanyShowcase />
                <Testimonial />
                <SolutionsSection />
                <TechStackBanner />
                <AppBanner />

                {/* Modals */}
                {actionType === LandingModalTypes.REGISTER_MODAL ? (
                    <RegisterModal
                        onClose={handleOnCloseModal}
                        setError={handleSetRegisterError}
                        loadMoreSuggestions={handleLoadMoreSuggestions}
                        onFetchDomainCompanies={handleFetchDomainCompanies}
                        onOrganisationChange={handleOrganisationChange}
                        onUserRegister={handleOnRegister}
                    />
                ) : null}

                {actionType === LandingModalTypes.CHANGE_PASSWORD_PROMPT ? (
                    <ChangePasswordPrompt
                        onClose={handleOnCloseModal}
                        onResendLink={handleResendLink}
                    />
                ) : null}
            </div>
        </div>
    );
}

export const LandingModalTypes = {
    REGISTER_MODAL: 'register_modal',
    CHANGE_PASSWORD_PROMPT: 'change_password_prompt',
};
