/**
 * User Profile Section
 */

import React, { useState, useRef, useEffect } from 'react';
import { useOutsideClickDetector } from '../../../../../utils/useOutsideClickDetector';
import { endpoints } from '../../../../../constants/endpoints';
import { sendEvent } from '../../../../../utils/sendEvent';
import { checkFeature } from '../../../../../utils/checkFeature';
import { CreditLabels } from './components/CreditLabels/index';
import Info from './components/Info';
import './styles.scss';

export const UserProfile = props => {
    const { profile, recruiterId, logout } = props;
    const goBackToOld = checkFeature('goBackToOld');
    const [menuOpen, setMenuOpen] = useState(false);
    const [creditsAdditionalClass, setcreditsAdditionalClass] = useState('');
    let divRef = useRef(null);
    useOutsideClickDetector(divRef, () => setMenuOpen(false));
    const legacyLabel = `origin=Menu,recId=${recruiterId},recEmail=${profile.email}`;
    const upgradeToPremiumLabel = `origin=Menu,recId=${recruiterId}`;
    useEffect(() => {
        if (parseInt(profile.premiumCredInfoArray.deactivated)) {
            setcreditsAdditionalClass('deactivateCreditsHeader');
        }
    }, []);

    return (
        <div className="action-icon profile profileImgAction">
            <img
                onClick={() => setMenuOpen(true)}
                className="user_img"
                alt="profile_pic"
                src={profile.img_link || '/static/images/profilenoImage.png'}
            />
            <div
                className={`profile-menu ${menuOpen ? 'active' : ''}`}
                ref={divRef}
            >
                <div className="profile-header">
                    <a href={endpoints.recruiterProfile}>
                        <p className="user-name user_name" id="">
                            {profile.name}
                            {parseInt(profile.contact_verify) === 1 ? (
                                <i
                                    className="icon-tick_circle"
                                    aria-hidden="true"
                                ></i>
                            ) : null}
                        </p>
                        <p className="user-email user_email" id="">
                            {profile.email}
                        </p>
                    </a>
                    <a
                        className="user-public-profile {{hiddenActionsDate}}"
                        href={profile.rurl}
                    >
                        <p className="user_public_profile {{hiddenActionsDate}}">
                            View Public Profile{' '}
                            <i className="icon-right_arrow"></i>
                        </p>
                    </a>
                </div>
                <div className="user_profile_info">
                    <span className="profile-hits">
                        <span className="profile-views">Viewed: </span>
                        {profile.hits}
                        <span> times</span>
                    </span>
                    <span className="profile-login {{hiddenActionsDate}}">
                        <span className="lastLogin">Last Login:</span>
                        {profile.lastLogin}
                    </span>
                </div>
                <div className=""></div>
                <div className="profile-body">
                    <div className="content-container">
                        <div className="basicAccount">
                            Basic Postings:{' '}
                            <span className="right-side">Unlimited</span>
                        </div>
                        <div className="premiumPostings">
                            <div className="basicAccount">
                                Premium Postings:
                                <span
                                    className={
                                        'right-side ' + creditsAdditionalClass
                                    }
                                >
                                    <span
                                        className={
                                            'credits ' + creditsAdditionalClass
                                        }
                                    >
                                        {' '}
                                        {profile.availableCredits}{' '}
                                    </span>
                                    Credits
                                </span>
                            </div>
                            <a
                                className="buy-credits-link"
                                href={`${endpoints.plans}?ref=menu`}
                                onClick={() => {
                                    sendEvent(
                                        'upgradeToPremium',
                                        upgradeToPremiumLabel,
                                    );
                                }}
                            >
                                Buy Credits
                            </a>
                        </div>
                        <div className="premiumCreditsMaxWidthProp">
                      <CreditLabels profile={props.profile} classAddOn={"headerClass"} monthLength={"long"} expiryText={`Credits expiring in ${props.profile.premiumCredInfoArray ? props.profile.premiumCredInfoArray.expiryDuration : ''}`} addHyperLink={true} addExclamation={false}/>
                      </div>
                      <div className="globalPostings">
                            <div className="basicAccount">
                                Global Postings:
                                <span
                                    className={
                                        'right-side ' + creditsAdditionalClass
                                    }
                                >
                                    <span
                                        className={
                                            'credits ' + creditsAdditionalClass
                                        }
                                    >
                                        {' '}
                                        {profile.companyCredits}{' '}
                                    </span>
                                    Credits
                                </span>
                                <Info />
                            </div>
                        </div>
                    </div>
                    <ul className="profile-item-listing">
                        {goBackToOld ? (
                            <li className="legacyRecruiter">
                                <a
                                    className="profile-item"
                                    onClick={() =>
                                        sendEvent('oldVersionView', legacyLabel)
                                    }
                                    href={endpoints.legacyRecruiterUrl}
                                >
                                    Access admin panel
                                </a>
                            </li>
                        ) : null}
                        {checkFeature("accountSettings") ? (
                            <li className="accountSettings settings">
                                <a
                                    className="profile-item"
                                    href={endpoints.recruiterProfile}
                                >
                                    Account Settings
                                </a>
                            </li>
                        ): null}
                        
                        <li onClick={logout} className="logout">
                            <span className="profile-item">Logout</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
