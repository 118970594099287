import React, { useState } from 'react';
import './styles.scss';

export const HeaderPropmpt = () => {
    const showPromptInitialValue = JSON.parse(
        typeof window !== 'undefined' && window.localStorage
            ? window.localStorage.getItem('domain-migration-header-propmpt')
            : null,
    );
    let show = true;
    if (
        showPromptInitialValue &&
        showPromptInitialValue.expiry &&
        new Date().getTime() - showPromptInitialValue.expiry <
            24 * 60 * 60 * 100
    ) {
        show = false;
    }

    const [showPrompt, setShowPrompt] = useState(show);

    const handleCrossIconClick = () => {
        setShowPrompt(false);
        localStorage.setItem(
            'domain-migration-header-propmpt',
            JSON.stringify({
                expiry: new Date().getTime(),
            }),
        );
        // let event = new Event('storage');
        // event.newValue = newValue;
        // window.dispatchEvent(event);
    };

    if (!showPrompt) {
        return <div className="domain-migration-header-propmt-wrapper"></div>;
    }
    return (
        <div className="domain-migration-header-propmt-wrapper">
            <div className="domain-migration-header-propmt-container">
                <div className="prompt-text-container">
                    <div className="prompt-text">
                        <span>recruit.hirist.com</span> has transitioned to a
                        new identity, <span>recruit.hirist.tech</span>.
                        Everything else remains the same.
                    </div>

                    <img
                        onClick={handleCrossIconClick}
                        className="cross-icon"
                        src="/static/images/close.webp"
                        alt="cross-icon"
                    />
                </div>
            </div>
        </div>
    );
};
