export function setLoginToken(headers) {
    var token = headers['set-token'];
    var universalToken = headers['set-universal-token'];
    var today = new Date();
    today.setTime(today.getTime());
    var expires = 1 * 1000 * 60 * 60 * 24;
    var expires_date = new Date(today.getTime() + expires);

    token = escape(token);
    var cookieName = process.env.REACT_APP_COOKIE_NAME;
    var oldCookie = process.env.REACT_APP_OLD_COOKIE_NAME;
    var domain = process.env.REACT_APP_BASE_DOMAIN_NAME;

    if (
        typeof window !== 'undefined' &&
        window.location.origin.includes('.tech') &&
        domain.includes('.com')
    ) {
        domain = domain.replace('.com', '.tech');
    }

    // document.cookie =
    //     cookieName +
    //     '=' +
    //     token +
    //     (expires ? ';expires=' + expires_date.toGMTString() : '') +
    //     ';path=/' +
    //     ';domain=' +
    //     domain;
    document.cookie =
        oldCookie +
        '=' +
        universalToken +
        (expires ? ';expires=' + expires_date.toGMTString() : '') +
        ';path=/' +
        ';domain=' +
        domain;
}
