/**
 * Call Sales Section
 */

import React, { useState, useRef, useEffect } from 'react';
import { useOutsideClickDetector } from '../../../../../utils/useOutsideClickDetector';
import './styles.scss';

export const ContactSales = props => {
    const { profile, recruiterId, logout } = props;
    const [menuOpen, setMenuOpen] = useState(false);
    let divRef = useRef(null);
    useOutsideClickDetector(divRef, () => setMenuOpen(false));

    return (
        // <div className="contact_us_menu_main_wrapper">
			<>
            {/* <img
                onClick={() => setMenuOpen(true)}
                className="contact_sales_img"
                alt="profile_pic"
                src="/static/images/callNew.svg"
            /> */}
			<span
                // onClick={() => setMenuOpen(true)}
				onMouseEnter={() => setMenuOpen(true)}
				onMouseLeave={() => setMenuOpen(false)}
                className="header-links"
			>
				Contact Us
            </span>
            <div
                className={`profile-menu profile-menu-contact-sales ${menuOpen ? 'active' : ''}`}
                ref={divRef}
            >
                <div class="contact-sales-menu inmenu">
					<div class="contact-sales-body">
						<div class="content_header">
							Connect with our Sales Team
						</div>
						<div class="content_body">
							<div class="content_main content_1">
								<div class="content_main_header">
									Sales Enquiries
								</div>
								<div class="content_main_body">
									<div class="content_main_body_text">
										<div class="content_icon">
											<i class="icon-telephone"></i>
										</div>
										1800-103-7344 <span>(9:30 AM to 6:30 PM)</span>
									</div>
									<div class="content_main_body_text">
										<div class="content_icon">
											<i class="icon-email_envelope"></i>
										</div>
										sales@{process.env.REACT_APP_BASE_DOMAIN_NAME}
									</div>
								</div>
							</div>
							<div class="content_main content_2">
								<div class="content_main_header">
									Customer Support
								</div>
								<div class="content_main_body">
									<div class="content_main_body_text">
										<div class="content_icon">
											<i class="icon-email_envelope"></i>
										</div>
										info@{process.env.REACT_APP_BASE_DOMAIN_NAME}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
			</>
    );
};
